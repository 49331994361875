.card-list {
    .card-list-item {
        padding: 1rem;
        border: none;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid #495057;
            border-radius: 0;
        }

        &:hover {
            background-color: var(--bs-primary);
        }
    }
}