.invoice-container {
    height: 83vh;

    .invoice-container-inner {
        height: 100%;

        .invoice-main {
            height: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #212529;
            }

            &::-webkit-scrollbar-thumb {
                background: #1A1D20;
            }
        }
    
        .invoice-sidebar {
            height: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #212529;
            }

            &::-webkit-scrollbar-thumb {
                background: #1A1D20;
            }
        }
    }
}