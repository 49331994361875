@import '~bootstrap/scss/bootstrap';

html {
    overflow-x: hidden;
}
body {
    overflow-x: hidden;
}

.hoverable-title {
    &:hover {
        cursor: pointer;
        background-color: #0A5ED7;
    }
}

.hoverable-title-alternative {
    &:hover {
        cursor: pointer;
        background-color: #198754;
    }
}

.no-border-on-last-child {
    .row:last-child {
        border-bottom: none !important;
    }

    tr:last-child {
        th {
            border-bottom: none !important;
        }
    }
}

.toast-container {
    position: fixed;
    right: 2rem;
    top: 2rem;
}

.listing-filter-dropdown {
    button {
        width: 100%;
        text-align: start;
        background-color: #212529;
        border-color: #495057;

        &:hover {
            cursor: default;
            background-color: #212529;
            border-color: #495057;
        }
    }

    div {
        width: 100%;
        min-width: fit-content;
    }
}

.course-tag-listing {
    grid-template-columns: 1fr 1fr 1fr;

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }
}