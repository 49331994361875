.accordion {
    .accordion-header {
        .accordion-button {
            background-color: #1A1D20;
            &:not(.collapsed) {
                color: white;
                background-color: #1A1D20;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .accordion-body {
        background-color: #1A1D20;
    }
}